import { Component, Input } from '@angular/core'
import { NgIf } from '@angular/common'
import { TooltipModule } from 'primeng/tooltip'

@Component({
  selector: 'tb-string-field',
  standalone: true,
  templateUrl: './string-field.component.html',
  imports: [
    NgIf,
    TooltipModule
  ]
})
export class StringFieldComponent {

  @Input({ required: true }) value: string = ''
  @Input() maxLength: number = 30
  @Input() tooltip: boolean = false

  get calculatedString(): string {
    if (typeof this.value === 'string') {
      return this.value.substring(0, this.maxLength) + '...'
    }

    return this.value
  }

}
